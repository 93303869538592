html,
body,
#root,
body {
    padding: 0;
    margin: 0;
    color: #000000;
}

.header {
    margin: 0 auto;
    min-width: 1440px;
    height: 88px;
    border: 1px solid #165a4a;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_name {
    width: 168px;
    height: 34px;
    margin-left: 60px;
    cursor: pointer;
}

.header_menu {
    display: flex;
    align-items: center;
}

.header_menu_item {
    font-size: 16px;
    margin-left: 48px;
    margin-right: 48px;
    cursor: pointer;
}

.header_menu_item_active {
    font-size: 16px;
    margin-left: 48px;
    margin-right: 48px;
    cursor: pointer;
    color: #165a4a;
}

.header_join {
    width: 127px;
    height: 88px;
    background: #165a4a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
}

.wrapper {
    margin: 0 auto;
    max-width: 1320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.row_0 {
    width: 100%;
}

.row_1 {
    width: 100%;
    background-color: #165a4a;
}

.wrapper_1 {
    margin: 0 auto;
    max-width: 1320px;
}

.footer {
    width: 100%;
    background-color: #ebf0ef;
}
